import React, { useRef, useState, useEffect } from "react";

import { Grid, Container, Box, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { MainButton } from "../components/buttons/buttons";
import Title from "../components/typography/title";
import Subtitle from "../components/typography/subtitle";
import colors from "../components/styling/colors";
import spacers from "../components/styling/spacers";
import BackgroundImg from "../images/mobile_hero.jpg";

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: "70em",
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "none",
		paddingTop: spacers.LARGE,
		paddingBottom: spacers.LARGE,
		"& > div:first-child": {
			display: "flex",
			flexFlow: "column nowrap",
			justifyContent: "space-around",
			alignItems: "center",
			minHeight: "50em"
		},
		position: "relative"
	},
	mobileImage: {
		[theme.breakpoints.down("xs")]: {
			position: "absolute",
			top: 0,
			minWidth: "100%",
			height: "100%",
			objectFit: "cover",
			zIndex: 1
		}
	},
	video: {
		position: "absolute",
		top: 0,
		minWidth: "100%",
		height: "100%",
		objectFit: "cover",
		zIndex: 1,
		[theme.breakpoints.down("xs")]: {
			display: "none"
		}
	},
	overlay: {
		height: "100%",
		width: "100%",
		background: "rgba(0,0,0,.7)",
		position: "absolute",
		zIndex: 2
	},
	text: {
		maxWidth: "79.25em",
		color: colors.WHITE,
		zIndex: 3
	}
}));

const MainValueProposition: React.FC = () => {
	const classes = useStyles();
	const mainRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const isPhone = useMediaQuery("(max-width:599px)");

	const backgroundDisplay = isLoading ? (
		<img src={BackgroundImg} alt="Hyperflyer Business Site Main Photo" className={classes.mobileImage} />
	) : isPhone ? (
		<img src={BackgroundImg} alt="Hyperflyer Business Site Main Photo" className={classes.mobileImage} />
	) : (
		<video
			loop
			autoPlay
			muted
			src="https://hyperflyer-public.s3-us-west-1.amazonaws.com/video/biz_website_optimized_2.mp4"
			className={classes.video}
		></video>
	);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	return (
		<Container className={classes.root}>
			{backgroundDisplay}
			<Box className={classes.overlay}></Box>

			<Grid
				ref={mainRef}
				container	
				direction="column"
				justify="center"
				alignItems="center"
				spacing={1}
				className={classes.text}
			>
				<Grid item>
					<Title variant="h1">We’re building you a commission-free online ordering and delivery system</Title>
				</Grid>

				<Grid>
					<Subtitle variant="h2">Growing your business doesn’t have to be expensive.</Subtitle>
				</Grid>

				<Grid item>
					<MainButton href="https://www.hyperflyer.com/contact-us/">Get Started</MainButton>
				</Grid>
			</Grid>
		</Container>
	);
};
export default MainValueProposition;
