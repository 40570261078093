import React from "react";

import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { MainButton } from "../components/buttons/buttons";
import SectionLayout from "../components/layouts/section";
import Title from "../components/typography/title";
import Subtitle from "../components/typography/subtitle";
import spacers from "../components/styling/spacers";
import colors from "../components/styling/colors.ts";

import BackgroundImg from "../images/svg-backgrounds/section6.svg";

const useStyles = makeStyles(theme => ({
	imageDisplay: {
		[theme.breakpoints.down("xs")]: {
			display: "none"
		}
	},
	headerSpacer: {
		marginBottom: spacers.MEDIUM,
		position: "relative"
		// overflowY: "hidden"
	},
	background: {
		position: "absolute",
		top: "-85%",
		width: "300vw",
		[theme.breakpoints.down("sm")]: {
			top: "0%",
			width: "100vw"
		}
	}
}));

const SocialValidationwithRestaurantLogos: React.FC = () => {
	const classes = useStyles();
	const isTablet = useMediaQuery("(max-width:959px)");
	const isPhone = useMediaQuery("(max-width:599px)");
	const imgSize = isPhone ? 12 : isTablet ? 6 : 4;

	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { absolutePath: { regex: "/.restaurant-logos./" } }) {
				edges {
					node {
						name
						relativeDirectory
						relativePath
						childImageSharp {
							fixed {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	`);

	const logos = [];
	data.allFile.edges.map(edge => {
		const imageName = edge.node.name;
		logos.push({
			name: imageName,
			image: edge.node.childImageSharp.fixed
		});
	});

	return (
		<SectionLayout background={colors.BACKGROUND_GRAY}>
			<Grid
				container
				direction="column"
				justify="space-between"
				alignItems="center"
				spacing={1}
				className={classes.headerSpacer}
			>
				<BackgroundImg className={classes.background} />
				<Grid item>
					<Title variant="h3">Join the Hyperflyer Community!</Title>
				</Grid>

				<Grid item>
					<Subtitle variant="body1">See which restaurants are already a part of the Hyperflyer Community!</Subtitle>
				</Grid>
			</Grid>

			<Grid container style={{ width: "130%" }}>
				{logos.map((logo, index) => {
					return (
						<Grid key={index} container item xs={imgSize} justify="center">
							<Img fixed={logo.image} alt={logo.name} style={{ padding: "10px" }}></Img>
						</Grid>
					);
				})}
			</Grid>

			<Grid item>
				<MainButton href="https://www.hyperflyer.com/contact-us/">Join the Hyperflyer Community</MainButton>
			</Grid>
		</SectionLayout>
	);
};

export default SocialValidationwithRestaurantLogos;
