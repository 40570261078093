import React from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import SectionLayout from "../components/layouts/section";
import Title from "../components/typography/title";
import Subtitle from "../components/typography/subtitle";
import colors from "../components/styling/colors";

import BackgroundImg from "../images/svg-backgrounds/section2.svg";

const useStyles = makeStyles(theme => ({
	root: {
		position: "relative"
	},
	background: {
		position: "absolute",
		width: "300vw",
		height: "100vh"
	}
}));

const ProductValueProposition: React.FC = () => {
	const classes = useStyles();
	const images = [];

	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { absolutePath: { regex: "/.hyperflyer devices./" } }) {
				edges {
					node {
						name
						childImageSharp {
							fluid(quality: 90) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`);
	data.allFile.edges
		.sort((edgeA, edgeB) => {
			return edgeA.node.name < edgeB.node.name ? -1 : 1;
		})
		.map(edge => {
			const imageName = edge.node.name;
			images.push({
				name: imageName,
				fluid: edge.node.childImageSharp.fluid
			});
		});

	return (
		<SectionLayout background={colors.BACKGROUND_GRAY}>
			<Grid
				container
				direction="column"
				justify="space-between"
				alignItems="center"
				spacing={1}
				className={classes.root}
			>
				<Grid item>
					<Title variant="h3">Reclaim control without breaking the bank</Title>
				</Grid>
				<BackgroundImg className={classes.background} />
				<Grid item>
					<Subtitle variant="body1">
						Our solutions are your alternative to third party delivery apps. We offer white-labeled and commission-free
						online ordering, on-demand flat-rate delivery, and marketing tools for growing your customer base...all with
						business-friendly pricing.
					</Subtitle>
				</Grid>
			</Grid>

			<Grid container item justify="center">
				<Img
					fluid={images[0].fluid}
					alt={images[0].name}
					imgStyle={{ objectFit: "contain" }}
					style={{
						width: "100%",
						marginTop: "100px"
					}}
				></Img>
			</Grid>
		</SectionLayout>
	);
};

export default ProductValueProposition;
