import React from "react";
import { Helmet } from "react-helmet";

import { CssBaseline } from "@material-ui/core";

import MainLayout from "../components/layouts/main";
import MainValueProposition from "../sections/1-Main-Value-Proposition";
import ProductValueProposition from "../sections/2-Product-Value-Proposition";
import SocialValueProposition from "../sections/3-Social-Value-Proposition";
import ReasonsForJoining from "../sections/5-Reasons-for-Joining";
import SocialValidationWithRestaurantLogos from "../sections/6-Social-Validation-with-Restaurant-Logos";
import Contact from "../sections/7-Contact";

const Main: React.FC = () => {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Hyperflyer Business Site</title>
				<meta name="description" content="Grow your business with Hyperflyer. Join the Hyperflyer community." />
			</Helmet>
			<MainLayout>
				<CssBaseline />
				<MainValueProposition />

				<ProductValueProposition />

				<SocialValueProposition />

				<ReasonsForJoining />

				<SocialValidationWithRestaurantLogos />

				{/* <Contact /> */}
			</MainLayout>
		</>
	);
};

export default Main;
