import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Grid, useMediaQuery, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { MainButton } from "../components/buttons/buttons";
import SectionLayout from "../components/layouts/section";
import Title from "../components/typography/title";
import Subtitle from "../components/typography/subtitle";
import spacers from "../components/styling/spacers";
import { fontStyles, fontSizes } from "../components/styling/font-styles";
import ImageStyles from "../components/styling/carousel-styles";
import WoodBackgroundImg from "../images/wood-background.png";

// const RESTAURANT_ROTATION_INTERVAL_MS = 5000;

const useStyles = makeStyles(theme => ({
	buttonSpacer: {
		marginBottom: spacers.MEDIUM
	},
	relativeContainer: {
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	relativeCenterContainer: {
		position: "relative",
		height: "450px"
	},
	carousel: {
		width: "100vw",
		// opacity: 0,
		// transitionProperty: "opacity",
		// transitionDuration: ".6s",
		"& .slide": {
			background: "rgba(0,0,0,0)",
			height: "700px",
			overflowX: "hidden",
			opacity: 0,
			transitionProperty: "opacity",
			transitionDuration: "1s"
		},
		"& .selected": {
			opacity: 1
		},
		"& > .selected": {
			opacity: 1,
			transform: "translate3d(0, 0, 0)"
		},
		"& .slider .animated": {
			transform: "translate3d(0, 0, 0)"
		}
	},
	largeText: {
		position: "absolute",
		top: "0px",
		minWidth: "300px",
		...fontStyles.LARGE_TEXT,
		marginTop: spacers.SMALL,
		marginBottom: spacers.XSMALL,
		[theme.breakpoints.down("xs")]: {
			fontSize: fontSizes.MEDIUM
		}
	},
	smallText: {
		position: "absolute",
		top: "80px",
		minWidth: "400px",
		...fontStyles.SMALL_TEXT,
		marginTop: spacers.SMALL,
		maxWidth: "26em",
		height: "50px"
	},
	divider: {
		position: "absolute",
		top: "75px",
		border: "1px solid #FFFFFF",
		width: "54px"
	}
}));

// function createFader(fadeName: string, start_ms: number, duration_ms: number, trackLength_ms: number) {
// 	const keyframeName = `@keyframes ${fadeName}`;

// 	const animation = {
// 		style: {
// 			animationName: "fader1",
// 			animationDuration: `${duration_ms}ms`,
// 			animationIterationCount: "infinite"
// 		},
// 		keyframes: {}
// 	};
// 	const keyframes = {};
// 	animation.keyframes[keyframeName] = keyframes;

// 	if (start_ms === 0) {
// 		keyframes["0%"] = { opacity: 1 };
// 	} else {
// 		keyframes["0%"] = { opacity: 0 };
// 		keyframes[`${Math.round((start_ms / trackLength_ms) * 100)}%`] = { opacity: 1 };
// 	}

// 	keyframes[`${Math.round(((start_ms + duration_ms) / trackLength_ms) * 100)}%`] = { opacity: 0 };

// 	return animation;
// }

const SocialValueProposition: React.FC = () => {
	const classes = useStyles();
	const isTablet = useMediaQuery("(max-width:959px)");
	const imgSize = isTablet ? 12 : 4;
	const centerImgSize = isTablet ? 12 : 4;
	// const [partitionIndex, setPartitionIndex] = useState(0);

	const imgText = [
		"Owner Jon (right) and his business partner, Jose, share the flavors of Mexico with their SoMa community. ",
		"Aya Ahn has run the shop at Bob’s since 2001, serving up the freshest donuts in San Francisco.",
		"Led by owner-chef Eva Morris, Voodoo Love’s true to its name, spreading love across the Bay Area for all things Creole-Nouveaux."
	];

	const data = useStaticQuery(graphql`
		query {
			carousel: allFile(filter: { absolutePath: { regex: "/.carousel./" } }, sort: { fields: relativePath }) {
				edges {
					node {
						name
						relativeDirectory

						childImageSharp {
							fixed {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	`);

	const {
		carousel: { edges }
	} = data;
	const nodes = edges.map(edge => edge.node);
	const nodePartitions = new Map();
	const restaurantNames: string[] = [];

	nodes.forEach(node => {
		const key = node.relativeDirectory.split("/")[1];
		if (!nodePartitions.has(key)) {
			restaurantNames.push(key);
			nodePartitions.set(key, []);
		}
	});

	const partitionKeys = [...nodePartitions.keys()];
	// const trackLength_ms = partitionKeys.length * RESTAURANT_ROTATION_INTERVAL_MS;
	// const animations = partitionKeys.map((_value, index) =>
	// 	createFader(
	// 		`fader${index}`,
	// 		index * RESTAURANT_ROTATION_INTERVAL_MS,
	// 		RESTAURANT_ROTATION_INTERVAL_MS,
	// 		trackLength_ms
	// 	)
	// );
	/*
	useEffect(() => {
		const keyframes = document.createElement("style");
		keyframes.textContent = 

	}, [animations]);
	*/

	nodes.forEach(node => {
		const key = node.relativeDirectory.split("/")[1];
		const partition = nodePartitions.get(key);
		const index = partition.length;
		const restaurantIndex = restaurantNames.indexOf(key);
		partition.push(
			<Img
				key={`${key}${index}`}
				// className={classes[`restaurant${restaurantIndex}`]}
				style={{ ...ImageStyles[index] }}
				fixed={node.childImageSharp.fixed}
				alt={node.name}
			/>
		);
	});

	// const restaurantName = partitionKeys[partitionIndex];
	const nodeImages = [...nodePartitions.values()];

	// const setA = nodeImages[0].slice(1, 4);

	return (
		<SectionLayout background={`url("${WoodBackgroundImg}")`}>
			<Grid container direction="column" justify="center" alignItems="center" spacing={1}>
				<Grid item>
					<Title variant="h3">In our world, relationships matter</Title>
				</Grid>

				<Grid item>
					<Subtitle variant="body1">
						We’ll help you share your story, reward customer loyalty, and connect with people in a way that promotes
						trust, friendship, and community.
					</Subtitle>
				</Grid>

				<Grid item className={classes.buttonSpacer}>
					<MainButton href="#reasonsForJoining">How We Work</MainButton>
				</Grid>
			</Grid>

			<Carousel
				useKeyboardArrows
				autoPlay
				infiniteLoop
				swipeable
				showStatus={false}
				interval={5000}
				transitionTime={1}
				showThumbs={false}
				className={classes.carousel}
			>
				<Grid container>
					<Grid
						container
						item
						direction="column"
						alignItems="flex-end"
						xs={imgSize}
						className={classes.relativeContainer}
					>
						{nodeImages[0].slice(1, 4)}
					</Grid>

					<Grid
						container
						item
						direction="column"
						alignItems="center"
						justify="flex-end"
						xs={centerImgSize}
						className={classes.relativeCenterContainer}
					>
						<Grid container item justify="center">
							{nodeImages[0].slice(0, 1)}
						</Grid>
						<Grid container item direction="column" alignItems="center" style={{ height: "60px" }}>
							<Grid container item justify="center" style={{ position: "relative" }}>
								<Typography align="center" className={classes.largeText}>
									Azúcar Lounge
								</Typography>
							</Grid>
							<Grid container item justify="center" style={{ position: "relative" }}>
								<div className={classes.divider}></div>
								<Typography align="center" className={classes.smallText}>
									{imgText[0]}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						item
						direction="column"
						alignItems="flex-end"
						xs={imgSize}
						className={classes.relativeContainer}
					>
						{nodeImages[0].slice(4)}
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						container
						item
						direction="column"
						alignItems="flex-end"
						xs={imgSize}
						className={classes.relativeContainer}
					>
						{nodeImages[1].slice(1, 4)}
					</Grid>

					<Grid
						container
						item
						direction="column"
						alignItems="center"
						justify="flex-end"
						xs={centerImgSize}
						className={classes.relativeCenterContainer}
					>
						<Grid container item justify="center">
							{nodeImages[1].slice(0, 1)}
						</Grid>
						<Grid container item direction="column" alignItems="center" style={{ height: "60px" }}>
							<Grid container item justify="center" style={{ position: "relative" }}>
								<Typography align="center" className={classes.largeText}>
									Bob's Donuts
								</Typography>
							</Grid>
							<Grid container item justify="center" style={{ position: "relative" }}>
								<div className={classes.divider}></div>
								<Typography align="center" className={classes.smallText}>
									{imgText[1]}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						item
						direction="column"
						alignItems="flex-end"
						xs={imgSize}
						className={classes.relativeContainer}
					>
						{nodeImages[1].slice(4)}
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						container
						item
						direction="column"
						alignItems="flex-end"
						xs={imgSize}
						className={classes.relativeContainer}
					>
						{nodeImages[0].slice(1, 4)}
					</Grid>

					<Grid
						container
						item
						direction="column"
						alignItems="center"
						justify="flex-end"
						xs={centerImgSize}
						className={classes.relativeCenterContainer}
					>
						<Grid container item justify="center">
							{nodeImages[2].slice(0, 1)}
						</Grid>
						<Grid container item direction="column" alignItems="center" style={{ height: "60px" }}>
							<Grid container item justify="center" style={{ position: "relative" }}>
								<Typography align="center" className={classes.largeText}>
									Voodoo Love
								</Typography>
							</Grid>
							<Grid container item justify="center" style={{ position: "relative" }}>
								<div className={classes.divider}></div>
								<Typography align="center" className={classes.smallText}>
									{imgText[2]}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						item
						direction="column"
						alignItems="flex-end"
						xs={imgSize}
						className={classes.relativeContainer}
					>
						{nodeImages[2].slice(4)}
					</Grid>
				</Grid>
			</Carousel>
		</SectionLayout>
	);
};

export default SocialValueProposition;
