import React from "react";

import { Grid, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import colors from "../components/styling/colors";
import spacers from "../components/styling/spacers";

import SectionLayout from "../components/layouts/section";
import Title from "../components/typography/title";
import { MainButton } from "../components/buttons/buttons";
import Checkmark from "../images/checkmark.svg";
import BackgroundImg from "../images/svg-backgrounds/section5.svg";

const useStyles = makeStyles(theme => ({
	titleContainer: {
		maxWidth: "920px",
		marginBottom: spacers.SMALL
	},
	listItem: {
		margin: "20px 0"
	},
	listItemText: {
		color: colors.BLACK,
		"& > span:first-child ": {
			[theme.breakpoints.down("xs")]: {
				fontSize: ".95em"
			}
		}
	},
	text: {
		color: colors.MEDIUM_GRAY
	},
	root: {
		position: "relative"
	},
	background: {
		position: "absolute",
		top: "-90%",
		width: "300vw"
	}
}));

const ReasonsForJoining: React.FC = () => {
	const classes = useStyles();
	const isTablet = useMediaQuery("(max-width:959px)");
	const isPhone = useMediaQuery("(max-width:599px)");
	const alignList = isTablet ? "center" : "flex-start";
	const listSize = isPhone ? 12 : isTablet ? 9 : 7;

	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { name: { eq: "hyperflyer tablet" } }) {
				edges {
					node {
						name
						childImageSharp {
							fluid(quality: 90) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`);
	const tablet = data.allFile.edges[0].node;

	const displayImg = isTablet ? null : (
		<Grid item xs={5} style={{ position: "relative" }}>
			<Img
				fluid={tablet.childImageSharp.fluid}
				alt={tablet.name}
				imgStyle={{ objectFit: "contain" }}
				style={{ width: "210%", position: "absolute", top: "60px", right: "0" }}
			/>
		</Grid>
	);
	return (
		<SectionLayout id="reasonsForJoining" background={colors.BACKGROUND_GRAY}>
			<Grid
				container
				direction="column"
				justify="space-between"
				alignItems="center"
				spacing={1}
				className={classes.root}
			>
				<BackgroundImg className={classes.background} />
				<Grid item className={classes.titleContainer}>
					<Title variant="h3">We are your one-stop shop for everything you need</Title>
				</Grid>
			</Grid>

			<Grid item container wrap="nowrap" justify="center" spacing={3}>
				{displayImg}

				<Grid item container direction="column" alignItems={alignList} xs={listSize}>
					<Grid item container>
						<List>
							<ListItem className={classes.listItem}>
								<ListItemIcon>
									<Checkmark />
								</ListItemIcon>
								<ListItemText
									primary="An intuitive, commission-free online order management system"
									className={classes.listItemText}
								/>
							</ListItem>
							<ListItem className={classes.listItem}>
								<ListItemIcon>
									<Checkmark />
								</ListItemIcon>
								<ListItemText primary="On-demand, flat-rate delivery" className={classes.listItemText} />
							</ListItem>
							<ListItem className={classes.listItem}>
								<ListItemIcon>
									<Checkmark />
								</ListItemIcon>
								<ListItemText
									primary="Marketing tools geared toward building your customer base"
									className={classes.listItemText}
								/>
							</ListItem>
							<ListItem className={classes.listItem}>
								<ListItemIcon>
									<Checkmark />
								</ListItemIcon>
								<ListItemText
									primary="An authentic and connective experience for customers when ordering online"
									className={classes.listItemText}
								/>
							</ListItem>
							<ListItem className={classes.listItem}>
								<ListItemIcon>
									<Checkmark />
								</ListItemIcon>
								<ListItemText primary="Access to real-time data and insights" className={classes.listItemText} />
							</ListItem>
							<ListItem className={classes.listItem}>
								<ListItemIcon>
									<Checkmark />
								</ListItemIcon>
								<ListItemText
									primary="A community-focused team supporting you every step of the way"
									className={classes.listItemText}
								/>
							</ListItem>
						</List>
					</Grid>

					<Grid item container direction="column" alignItems="center">
						<Grid item>
							<MainButton href="https://www.hyperflyer.com/contact-us/">Get Started</MainButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</SectionLayout>
	);
};

export default ReasonsForJoining;
