interface ImageStyle {
	position?: string;
	top?: string;
	right?: string;
	left?: string;
	height: string;
	width: string;
	transform?: string;
	borderRadius: string;
}

// configurations for different images; add/remove below as necessary

const Image1: ImageStyle = {
	position: "absolute",
	top: "70px",
	height: "300px",
	width: "300px",
	borderRadius: "50%"
};

const Image2: ImageStyle = {
	position: "absolute",
	top: "120px",
	right: "30px",
	height: "171px",
	width: "115px",
	transform: "rotate(16.23deg)",
	borderRadius: "8px"
};

const Image3: ImageStyle = {
	position: "absolute",
	top: "50px",
	right: "150px",
	height: "214px",
	width: "143px",
	transform: "rotate(-15deg)",
	borderRadius: "8px"
};

const Image4: ImageStyle = {
	position: "absolute",
	top: "350px",
	right: "40px",
	height: "200px",
	width: "300px",
	transform: "rotate(10.68deg)",
	borderRadius: "8px"
};

const Image5: ImageStyle = {
	position: "absolute",
	top: "40px",
	left: "30px",
	height: "200px",
	width: "300px",
	transform: "rotate(-8.14deg)",
	borderRadius: "8px"
};

const Image6: ImageStyle = {
	position: "absolute",
	top: "180px",
	left: "175px",
	height: "137px",
	width: "206px",
	transform: "rotate(15deg)",
	borderRadius: "8px"
};

const Image7: ImageStyle = {
	position: "absolute",
	top: "320px",
	left: "75px",
	height: "300px",
	width: "200px",
	transform: "rotate(6.31deg)",
	borderRadius: "8px"
};

const ImageStyles = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

export default ImageStyles;
